import axios from "../../axios";

const state = {
    counters: {
        making: 0,
        pending: 0,
        delivering: 0,
    },
    searchFilters: {},
    auctionPaging: [],
    auctionPagingPerPage: [],
    auctionPagingCurrentPage: 0,
    auctionPagingTotal: [],
    auctions: [],
    filter: {},
    baseUrl: 'https://www.althawri.com/api/v1',
};

const getters = {
    getAuctionPaging: state => state.auctionPaging,
    getAuctionPagingPerPage: state => state.auctionPagingPerPage,
    getAuctionPagingCurrentPage: state => state.auctionPagingCurrentPage,
    getAuctionPagingTotal: state => state.auctionPagingTotal,
    getSearchFilters: state => state.searchFilters,
    getAuctions: state => state.auctions,
    getFilter: state => state.filter,
};
const mutations = {
    setAuctionPaging(state, payload) {
        let paging = [];
        if (payload.last_page > 1) {
            paging.push({
                text: 1,
                value: 1
            });
        }
        if (payload.last_page > 2) {
            paging.push({
                text: 2,
                value: 2
            });
        }
        if (payload.last_page > 3) {
            paging.push({
                text: 3,
                value: 3
            });
        }
        if (payload.last_page > 5) {
            paging.push({
                text: 'Next',
                value: -1
            });
        }
        if (payload.last_page > 1) {
            paging.push({
                text: payload.last_page,
                value: payload.last_page
            });
        }
        console.log('payload:', paging);
        state.auctionPaging = paging;
        state.auctionPagingPerPage = payload.to;
        state.auctionPagingCurrentPage = payload.current_page;
        state.auctionPagingTotal = payload.total;
    },
    setSearchFilters(state, payload) {
        state.searchFilters = payload;
    },
    setAuctions(state, payload) {
        state.auctions = payload;
    },
    setFilter(state, payload) {
        state.filter = payload;
    },
};
const actions = {
    fetchSearchBrandModels({ commit }, item) {
        return axios.get(`/search-filters-models/${item}`)
            .then((response) => {
                commit("setSearchBrandModels", response.data.data.models)
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchAuctions({ commit }, query) {
        let search = query.search ? query.search : "";
        search ? state.filter = { page: 1 } : state.filter = { page: state.filter.page };
        commit("setFilter", query)
        return axios.get(`/page/auctions`, { params: state.filter })
            .then((response) => {
                commit("setAuctions", response.data.data.auctions.data)
                commit("setAuctionPaging", response.data.data.auctions)
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
};

export default {
    namespaced: true,
    name: "auction",
    state,
    getters,
    actions,
    mutations,
};