import axios from "../../axios";

const state = {
    walleterrors: [],
    dashboard: [],
    wauctions: [],
    errors: [],
    sauctions: [],
    notifications: [],
    favourite: [],
    shipping: [],
    contactus: [],
    about: [],
    wallet: [],
    wallet_bank_note: "",
    baseUrl: 'https://www.althawri.com/api/v1',
};

const getters = {
    getDashboard: state => state.dashboard,
    getWAuctions: state => state.wauctions,
    getSAuctions: state => state.sauctions,
    getNotifications: state => state.notifications,
    getFavourite: state => state.favourite,
    getShipping: state => state.shipping,
    getContactUs: state => state.contactus,
    getAbout: state => state.about,
    getWallet: state => state.wallet,
    getErrors: state => state.errors,
};
const mutations = {
    setErrors(state, payload) {
        state.errors = payload;
        state.walleterrors = payload;
    },
    setDashboard(state, payload) {
        state.dashboard = payload;
    },
    setWAuctions(state, payload) {
        state.wauctions = payload;
    },
    setSAuctions(state, payload) {
        state.sauctions = payload;
    },
    setNotifications(state, payload) {
        state.notifications = payload;
    },
    setFavourite(state, payload) {
        state.favourite = payload;
    },
    setShipping(state, payload) {
        state.shipping = payload;
    },
    setContactUs(state, payload) {
        state.contactus = payload;
    },
    setAbout(state, payload) {
        state.about = payload;
    },
    setWallet(state, payload) {
        state.wallet = payload;
        state.wallet_bank_note = payload.wallet_bank_note;
    },
};
const actions = {
    subscribe({ commit }, payload) {
        const headers = { 'Content-Type': 'multipart/form-data' };
        return axios.post(`/complete-win-order`, payload, { headers }).then((response) => {
            // console.log("response", response);
            if (response.data.code != 200) {
                commit("setErrors", response.data.errors)
            }
            return response;
        }).catch((error) => {
            commit("setErrors", error.response.data.errors)
            return error;
        });
    },
    fetchDashboard({ commit }) {
        return axios.get(`/dashboard`)
            .then((response) => {
                commit("setDashboard", response.data.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchWAuctions({ commit }) {
        return axios.get(`/win-auctions`)
            .then((response) => {
                commit("setWAuctions", response.data.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchSAuctions({ commit }) {
        return axios.get(`/subscribe-auctions`)
            .then((response) => {
                commit("setSAuctions", response.data.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchFavourite({ commit }) {
        return axios.get(`/favourites`)
            .then((response) => {
                commit("setFavourite", response.data.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchNotifications({ commit }) {
        return axios.get(`/notifications`)
            .then((response) => {
                commit("setNotifications", response.data.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchContactUs({ commit }) {
        return axios.get(`/contact-us`)
            .then((response) => {
                commit("setContactUs", response.data.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchShipping({ commit }) {
        return axios.get(`/shipping`)
            .then((response) => {
                commit("setShipping", response.data.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchAbout({ commit }) {
        return axios.get(`/about`)
            .then((response) => {
                commit("setAbout", response.data.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchWallet({ commit }, payload) {
        console.log("payload", payload);
        return axios.get(`/wallet?` + payload)
            .then((response) => {
                commit("setWallet", response.data.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    storeBankTransfer({ commit }, payload) {
        const headers = { 'Content-Type': 'multipart/form-data' };
        return axios.post(`/store-bank-transfer`, payload, { headers }).then((response) => {
            console.log(response)
                // if (response.data.code != 200) {
            commit("setErrors", response.data.errors)
                // }
            commit("setWallet", response.data.data)
            return response;
        }).catch((error) => {
            commit("setErrors", error.response.data.errors)
            return error;
            console.log(error)
        });
    },
};

export default {
    namespaced: true,
    name: "user",
    state,
    getters,
    actions,
    mutations,
};