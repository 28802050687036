import axios from "../../axios";

const state = {
    testmonialsList: [],
    filter: { page: 1 },
    last_page: 0,
    baseUrl: 'https://www.althawri.com/',
};

const getters = {
    gettestmonialsList: state => state.testmonialsList,
    getFilter: state => state.filter,
    getLastPage: state => state.last_page,


};
const mutations = {
    settestmonialsList(state, payload) {
        state.testmonialsList = payload;
    },
    setLastPage(state, payload) {
        state.last_page = payload;
    },
    setFilter(state, payload) {
        state.filter = payload;
    },
    setImageURL(state, payload) {
        state.image = (state.baseUrl + payload);
    },
    addtestmonials(state, payload) {
        state.testmonial = payload;
    }



};
const actions = {
    fetchtestmonialsList({ state, commit }, query) {
        let search = query.search ? query.search : "";
        search ? state.filter = { page: 1 } : state.filter = { page: state.filter.page };
        return axios.get(`/api/admin/testmonials`, { params: state.filter })
            .then((response) => {
                commit("settestmonialsList", response.data.data)
                commit("setLastPage", response.data.last_page);
                console.log(response.data.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    alert(Object.values(error.response.data)[i].toString());
                }
            });
    },

    //posttestmonial
    posttestmonial({ commit }, testmonial) {

        let formData = new FormData();
        formData.append("name", testmonial.name);
        formData.append("nameAr", testmonial.nameAr);
        formData.append("text", testmonial.text);
        formData.append("textAr", testmonial.textAr);
        formData.append("image", testmonial.image);
        return axios.post(`api/admin/testmonials`, formData).then((response) => {
            alert("تم اضافة المنتج بنجاح");
            commit("addtestmonial", response.data)
            return response;
        }).catch((error) => {
            for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                alert(Object.values(error.response.data)[i].toString());
            }
        });
    },




    //updatetestmonial
    updatetestmonials({ commit }, testmonial) {
        let image = (`https://www.althawri.com/`, testmonial.image); //concat the image url
        let formData = new FormData();
        formData.append("name", testmonial.name);
        formData.append("nameAr", testmonial.nameAr);
        formData.append("text", testmonial.text);
        formData.append("textAr", testmonial.textAr);
        formData.append("image", testmonial.image);
        if (typeof(image) != "object" || image == null) {
            formData.delete('image');
        }
        return axios.post(`/api/admin/testmonials/${testmonial.id}?_method=PUT`, formData).then((response) => {
            commit("settestmonial", response.data)
                // commit("setImageURL", response.data.image)
            console.log(response.data.image);
            console.log("image state", state.image);

            alert("data sent")
            return response;
        }).catch((err) => {
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                alert(Object.values(err.response.data.errors)[i].toString());
            }
        });
    },



    deletetestmonial({ commit }, id) {
        return axios.delete(`/api/testmonials/${id}`).then((response) => {
            commit("deletetestmonial", id)
            return response;
        }).catch((err) => {
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                alert(Object.values(err.response.data.errors)[i].toString());
            }
        });

    },


    getSingleTestmonial({ commit }, id) {
        return axios.get(`/api/admin/testmonials/${id}`).then((response) => {
            commit("settestmonial", response.data)
            return response;
        }).catch((error) => {
            for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                alert(Object.values(error.response.data)[i].toString());
            }
        });
    }


};

export default {
    namespaced: true,
    name: "testmonials",
    state,
    getters,
    actions,
    mutations,
};