import axios from "../../axios";

const state = {
    placesList: [],
    place: null,
    filter: { page: 1 },
    last_page: 0,
    baseUrl: 'https://www.althawri.com/',
};

const getters = {
    getplacesList: state => state.placesList,
    getFilter: state => state.filter,
    getLastPage: state => state.last_page,


};
const mutations = {
    ListsList(state, payload) {
        state.placesList = payload;
    },
    setLastPage(state, payload) {
        state.last_page = payload;
    },
    setFilter(state, payload) {
        state.filter = payload;
    },
    setImageURL(state, payload) {
        state.image = (state.baseUrl + payload);
    },
    addplaces(state, payload) {
        state.placesList.push(payload);
    }



};
const actions = {
    fetchplacesList({ state, commit }, query) {
        let search = query.search ? query.search : "";
        search ? state.filter = { page: 1 } : state.filter = { page: state.filter.page };
        return axios.get(`/api/admin/places`, { params: state.filter })
            .then((response) => {
                commit("ListsList", response.data.data)
                commit("setLastPage", response.last_page);
                console.log(response.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },

    //postplace
    postplace({ commit }, place) {

        let formData = new FormData();
        formData.append("name", place.name);
        formData.append("details", place.details);
        formData.append("address", place.address);
        formData.append("link", place.link);
        formData.append("image", place.image);
        return axios.post(`api/admin/places`, formData).then((response) => {
            console.log("تم اضافة المكان بنجاح");
            commit("addplaces", response.data)
            return response;
        }).catch((error) => {
            for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                console.log(Object.values(error.response.data)[i].toString());
            }
        });
    },




    //updateplace
    updateplace({ commit }, place) {
        let formData = new FormData();
        formData.append("name", place.name);
        formData.append("details", place.details);
        formData.append("address", place.address);
        formData.append("link", place.link);
        formData.append("image", place.image);
        // if(typeof(image) != "object" || image == null){
        //     formData.delete('image');
        // }
        return axios.post(`/api/admin/places/${place.id}?_method=PUT`, formData).then((response) => {
            commit("addplaces", response.data)
                // commit("setImageURL", response.data.image)
            console.log(response.data.image);
            console.log("data sent")
            return response;
        }).catch((err) => {
            console.log(err.response)
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                console.log(Object.values(err.response.data.errors)[i].toString());
            }
        });
    },



    deleteplace({ commit }, id) {
        return axios.delete(`/api/admin/places/${id}`).then((response) => {
            commit("deleteplace", id)
            return response;
        }).catch((err) => {
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                console.log(Object.values(err.response.data.errors)[i].toString());
            }
        });

    },


    getSingleplace({ commit }, id) {
        return axios.get(`/api/admin/places/${id}`).then((response) => {
            commit("List", response.data)
            return response;
        }).catch((error) => {
            for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                console.log(Object.values(error.response.data)[i].toString());
            }
        });
    }


};

export default {
    namespaced: true,
    name: "places",
    state,
    getters,
    actions,
    mutations,
};