import axios from "../../axios";

const state = {
    partenerRequestsList: [],
    filter: { page: 1 },
    last_page: 0,
    baseUrl: 'https://www.althawri.com/',
};

const getters = {
    getpartenerRequestsList: state => state.partenerRequestsList,
    getFilter: state => state.filter,
    getLastPage: state => state.last_page,


};
const mutations = {
    setpartenerRequestsList(state, payload) {
        state.partenerRequestsList = payload;
    },
    setLastPage(state, payload) {
        state.last_page = payload;
    },
    setFilter(state, payload) {
        state.filter = payload;
    },
    setImageURL(state, payload) {
        state.image = (state.baseUrl + payload);
    },
    addpartenerRequests(state, payload) {
        state.partenerRequest = payload;
    }



};
const actions = {
    fetchpartenerRequestsList({ state, commit }, query) {
        let search = query.search ? query.search : "";
        search ? state.filter = { page: 1 } : state.filter = { page: state.filter.page };
        return axios.get(`/api/admin/partener-requests`, { params: state.filter })
            .then((response) => {
                commit("setpartenerRequestsList", response.data.data)
                commit("setLastPage", response.last_page);
                console.log(response.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },

    //postpartenerRequest
    postpartenerRequest({ commit }, partenerRequest) {

        let formData = new FormData();
        formData.append("titleAr", partenerRequest.titleAr);
        formData.append("titleEn", partenerRequest.titleEn);
        formData.append("placeAr", partenerRequest.placeAr);
        formData.append("placeEn", partenerRequest.placeEn);
        formData.append("detailsAr", partenerRequest.detailsAr);
        formData.append("detailsEn", partenerRequest.detailsEn);
        formData.append("hours", partenerRequest.hours);
        formData.append("shift", partenerRequest.shift);
        return axios.post(`api/admin/partenerRequests`, formData).then((response) => {
            console.log("تم اضافة المنتج بنجاح");
            commit("addpartenerRequests", response.data)
            return response;
        }).catch((error) => {
            for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                console.log(Object.values(error.response.data)[i].toString());
            }
        });
    },




    //updatepartenerRequest
    updatepartenerRequest({ commit }, partenerRequest) {
        let image = (`https://www.althawri.com/`, partenerRequest.image); //concat the image url
        let formData = new FormData();
        formData.append("name", partenerRequest.name);
        formData.append("price", partenerRequest.price);
        formData.append("image", image);
        formData.append("nameAr", partenerRequest.nameAr);
        formData.append("category_id", partenerRequest.category_id);
        console.log("image out ", image);
        if (typeof(image) != "object" || image == null) {
            formData.delete('image');
        }
        return axios.post(`/api/admin/partenerRequests/${partenerRequest.id}?_method=PUT`, partenerRequest).then((response) => {
            commit("setpartenerRequest", response.data)
                // commit("setImageURL", response.data.image)
            console.log(response.data.image);
            console.log("image state", state.image);

            console.log("data sent")
            return response;
        }).catch((err) => {
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                console.log(Object.values(err.response.data.errors)[i].toString());
            }
        });
    },



    deletepartenerRequest({ commit }, id) {
        return axios.delete(`/api/partenerRequests/${id}`).then((response) => {
            commit("deletepartenerRequest", id)
            return response;
        }).catch((err) => {
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                console.log(Object.values(err.response.data.errors)[i].toString());
            }
        });

    },


    getSinglepartenerRequest({ commit }, id) {
        return axios.get(`/api/admin/partenerRequests/${id}`).then((response) => {
            commit("setpartenerRequest", response.data)
            return response;
        }).catch((error) => {
            for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                console.log(Object.values(error.response.data)[i].toString());
            }
        });
    }


};

export default {
    namespaced: true,
    name: "partenerRequests",
    state,
    getters,
    actions,
    mutations,
};