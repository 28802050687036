import Vue from "vue";
import axios from "../../axios";

const state = {
    profile: [],
    notifications: [],
    countries: [],
    products: [],
    errors: [],
    baseUrl: 'https://www.althawri.com/api/v1',
};

const getters = {
    getProfile: state => state.profile,
    getNotifications: state => state.notifications,
    getCountries: state => state.countries,
    getFavourites: state => state.products,
    getProducts: state => state.products,
};

const mutations = {
    setProfile(state, payload) {
        state.profile = payload;
    },
    setCountries(state, payload) {
        state.countries = payload;
    },
    setNotifications(state, payload) {
        state.notifications = payload;
    },
    setFavourites(state, payload) {
        state.products = payload;
    },
    setProducts(state, payload) {
        state.products = payload;
    },
    Seterrors(state, payload) {
        state.errors = payload;
    },
};
const actions = {
    subscribe({ commit }, payload) {
        const headers = { 'Content-Type': 'multipart/form-data' };
        return axios.post(`/complete-win-order`, payload, { headers }).then((response) => {
            if (response.data.code != 200)
                commit("Seterrors", response.data.errors)
            return response;
        }).catch((error) => {
            commit("Seterrors", error.response.data.errors)
            return error;
        });
    },
    fetchNotifications({ commit }) {
        return axios.get(`/notifications`)
            .then((response) => {
                commit("setNotifications", response.data.data.notifications)
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchProfile({ commit }) {
        return axios.get(`/profile`)
            .then((response) => {
                if (response.data.code == 200) {
                    commit("setProfile", response.data.data.user)
                    commit("setCountries", response.data.data.countries)

                }
                // this.$store.state.home.isLoading = false;
                return response;
            }).catch((error) => {
                if (error.response.status == 403 || error.response.status == 401) {
                    window.localStorage.removeItem('authToken')
                    location.replace('/')
                }
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    updateProfile({ commit }, payload) {
        // var body = {
        //     product_id: payload.product_id,
        //     amount: payload.amount,
        // }
        const headers = { 'Content-Type': 'multipart/form-data' };
        return axios.post(`/update-profile`, payload, { headers }).then((response) => {
            console.log(response)
            if (response.data.code == 200) {
                commit("setProfile", response.data.data.user)
            }
            return response;
        }).catch((error) => {
            console.log(error)
            Vue.$toast.open({
                message: error.response.data.message,
                type: 'error',
                duration: 5000
                    // all of other options may go here
            });
        });
    },
    DeleteNotification({ commit }, payload) {
        const headers = { 'Content-Type': 'multipart/form-data' };
        return axios.post(`/delete-notification`, payload, { headers }).then((response) => {
            commit("setNotifications", response.data.data.notifications);
            return response;
        }).catch((error) => {
            console.log(error)
        });
    },
    ReadNotification({ commit }, payload) {
        const headers = { 'Content-Type': 'multipart/form-data' };
        return axios.post(`/read-notification`, payload, { headers }).then((response) => {
            commit("setNotifications", response.data.data.notifications);
            return response;
        }).catch((error) => {
            console.log(error)
        });
    },
    fetchFavourites({ commit }, payload) {
        return axios.get(`/favourite`)
            .then((response) => {
                commit("setFavourites", response.data.data.products)
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchWinAuactions({ commit }) {
        return axios.get(`/win-auctions`)
            .then((response) => {
                commit("setProducts", response.data.data.products)
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    fetchSubAuactions({ commit }) {
        return axios.get(`/subscribe-auctions`)
            .then((response) => {
                commit("setProducts", response.data.data.products)
                console.log("test T")
                return response;
            }).catch((error) => {
                console.log(error);
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
};

export default {
    namespaced: true,
    name: "profile",
    state,
    getters,
    actions,
    mutations,
};