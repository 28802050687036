import axios from "../../axios";

const state = {
    car: [],
    win_car: {},
    code: 200,
    message: '',
    notfound: false,
    notfoundmsg: '',
    auction_close_time_minus_3: null,
    related: [],
    baseUrl: 'https://www.althawri.com/api/v1',
};

const getters = {
    getCar: state => state.car,
    getRelated: state => state.related,
    getMS: state => state.auction_close_time_minus_3,
};
const mutations = {
    setCar(state, payload) {
        state.car = payload;
    },
    setNotFound(state, flag) {
        state.notfound = flag;
    },
    setNotFoundmsg(state, msg) {
        state.notfoundmsg = msg;
    },
    setRelated(state, payload) {
        state.related = payload;
    },
    setCode(state, payload) {
        state.code = payload;
    },
    setCode(state, payload) {
        state.code = payload;
    },
    setConst(state, payload) {
        state.auction_close_time_minus_3 = payload;
    },
    setMessage(state, payload) {
        state.message = payload;
    },
};
const actions = {
    fetchCar({ commit }, item) {
        return axios.get(`/page/car/${item}`)
            .then((response) => {
                if (response.data.code == 404) {
                    commit("setNotFound", true)
                    commit("setNotFoundmsg", response.data.message)
                } else {
                    commit("setNotFound", false)
                    commit("setNotFoundmsg", '')

                }
                commit("setCar", response.data.data.car)
                commit("setRelated", response.data.data.related)
                commit("setCode", response.data.code)
                commit("setConst", response.data.data.auction_close_time_minus_3)
                commit("setMessage", response.data.message)
                return response;
            }).catch((error) => {
                commit("setNotFound", true)
                commit("setNotFoundmsg", 'something went wrong')
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },
    bid({ commit }, payload) {
        var body = {
            product_id: payload.product_id,
            amount: payload.amount,
            less_5: payload.less_5,
        }
        return axios.post(`/bid`, body).then((response) => {
            console.log(response)
            commit("setCar", response.data.data.car)
            commit("setCode", response.data.code)
            commit("setMessage", response.data.message)
            return response;
        }).catch((error) => {
            console.log(error)
        });
    },
    buy({ commit }, payload) {
        var body = {
            product_id: payload.product_id,
            amount: payload.amount,
        }
        return axios.post(`/buy`, body).then((response) => {
            commit("setCode", response.data.code)
            commit("setMessage", response.data.message)
            return response;
        }).catch((error) => {
            console.log(error)
        });
    },
    toggleFav({ commit }, payload) {
        var body = {
            product_id: payload.product_id,
            amount: payload.amount,
        }
        return axios.post(`/toggle-favourite`, body).then((response) => {
            return response;
        }).catch((error) => {
            console.log(error)
        });
    },
};

export default {
    namespaced: true,
    name: "car",
    state,
    getters,
    actions,
    mutations,
};