import axios from "../../axios";

const state = {
    couponsList: [],
    filter: { page: 1 },
    last_page: 0,
    baseUrl: 'https://www.althawri.com/',
};

const getters = {
    getcouponsList: state => state.couponsList,
    getFilter: state => state.filter,
    getLastPage: state => state.last_page,


};
const mutations = {
    setcouponsList(state, payload) {
        state.couponsList = payload;
    },
    setLastPage(state, payload) {
        state.last_page = payload;
    },
    setFilter(state, payload) {
        state.filter = payload;
    },
    setImageURL(state, payload) {
        state.image = (state.baseUrl + payload);
    }



};
const actions = {
    fetchcouponsList({ state, commit }, query) {
        let search = query.search ? query.search : "";
        search ? state.filter = { page: 1 } : state.filter = { page: state.filter.page };
        return axios.get(`/api/admin/coupons`, { params: state.filter })
            .then((response) => {
                commit("setcouponsList", response.data.data)
                commit("setLastPage", response.data.last_page);
                console.log("coupouuns", response.data.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    alert(Object.values(error.response.data)[i].toString());
                }
            });
    },

    //postcoupon
    postcoupon({ commit }, coupon) {

        let formData = new FormData();
        formData.append("tille", coupon.tille);
        formData.append("code", coupon.code);
        formData.append("discountCash", coupon.discountCash);
        formData.append("discountPercentage", coupon.discountPercentage);
        formData.append("maxDiscount", coupon.maxDiscount);
        formData.append("start_at", coupon.start_at);
        formData.append("end_at", coupon.end_at);
        return axios.post(`api/admin/coupons`, formData).then((response) => {
            console.log("تم اضافة الكوبون بنجاح");
            commit("addcoupon", response.data)
            return response;
        }).catch((error) => {
            for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                console.log(Object.values(error.response.data)[i].toString());
            }
        });
    },




    //updatecoupon
    updatecoupon({ commit }, coupon) {
        let image = (`https://www.althawri.com/`, coupon.image); //concat the image url
        let formData = new FormData();
        formData.append("name", coupon.name);
        formData.append("price", coupon.price);
        formData.append("image", image);
        formData.append("nameAr", coupon.nameAr);
        formData.append("category_id", coupon.category_id);
        console.log("image out ", image);
        if (typeof(image) != "object" || image == null) {
            formData.delete('image');
        }
        return axios.post(`/api/admin/coupons/${coupon.id}?_method=PUT`, formData).then((response) => {
            commit("setcoupon", response.data)
                // commit("setImageURL", response.data.image)
            console.log(response.data.image);
            console.log("image state", state.image);

            alert("data sent")
            return response;
        }).catch((err) => {
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                alert(Object.values(err.response.data.errors)[i].toString());
            }
        });
    },



    deletecoupon({ commit }, id) {
        return axios.delete(`/api/coupons/${id}`).then((response) => {
            commit("deletecoupon", id)
            return response;
        }).catch((err) => {
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                alert(Object.values(err.response.data.errors)[i].toString());
            }
        });

    },


    getSinglecoupon({ commit }, id) {
        return axios.get(`/api/admin/coupons/${id}`).then((response) => {
            commit("setcoupon", response.data)
            return response;
        }).catch((error) => {
            for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                alert(Object.values(error.response.data)[i].toString());
            }
        });
    }


};

export default {
    namespaced: true,
    name: "coupons",
    state,
    getters,
    actions,
    mutations,
};