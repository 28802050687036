import axios from "../../axios";

const state = {
    ordersList: [],
    filter: { page: 1 },
    last_page: 0,
    baseUrl: 'https://www.althawri.com/',
};

const getters = {
    getordersList: state => state.ordersList,
    getFilter: state => state.filter,
    getLastPage: state => state.last_page,


};
const mutations = {
    setordersList(state, payload) {
        state.ordersList = payload;
    },
    setLastPage(state, payload) {
        state.last_page = payload;
    },
    setFilter(state, payload) {
        state.filter = payload;
    },
    setImageURL(state, payload) {
        state.image = (state.baseUrl + payload);
    },
    addorders(state, payload) {
        state.order = payload;
    }



};
const actions = {
    fetchordersList({ state, commit }, query) {
        let search = query.search ? query.search : "";
        search ? state.filter = { page: 1 } : state.filter = { page: state.filter.page };
        return axios.get(`/api/admin/orders/pending`, { params: state.filter })
            .then((response) => {
                commit("setordersList", response.data.data)
                commit("setLastPage", response.last_page);
                console.log(response.data);
                return response;
            }).catch((error) => {
                for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                    console.log(Object.values(error.response.data)[i].toString());
                }
            });
    },

    //postorder
    postorder({ commit }, order) {
        let formData = new FormData();
        formData.append("titleAr", order.titleAr);
        formData.append("titleEn", order.titleEn);
        formData.append("placeAr", order.placeAr);
        formData.append("placeEn", order.placeEn);
        formData.append("detailsAr", order.detailsAr);
        formData.append("detailsEn", order.detailsEn);
        formData.append("hours", order.hours);
        formData.append("shift", order.shift);
        return axios.post(`api/admin/orders`, formData).then((response) => {
            console.log("تم اضافة المنتج بنجاح");
            commit("addorders", response.data)
            return response;
        }).catch((error) => {
            for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                console.log(Object.values(error.response.data)[i].toString());
            }
        });
    },




    //updateorder
    updateorder({ commit }, order) {
        return axios.post(`/api/admin/orders/make/${order.id}?_method=PUT`).then((response) => {
            commit("deleteorder", order.id)
            console.log("data sent")
            return response;
        }).catch((err) => {
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                console.log(Object.values(err.response.data.errors)[i].toString());
            }
        });
    },
    //updateorder
    cancelorder({ commit }, order) {
        return axios.post(`/api/admin/orders/cancel/${order.id}?_method=PUT`).then((response) => {
            commit("deleteorder", order.id)
            console.log("data sent")
            return response;
        }).catch((err) => {
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                console.log(Object.values(err.response.data.errors)[i].toString());
            }
        });
    },



    deleteorder({ commit }, id) {
        return axios.delete(`/api/orders/${id}`).then((response) => {
            commit("deleteorder", id)
            return response;
        }).catch((err) => {
            for (let i = 0; i < Object.keys(err.response.data).length; i++) {
                console.log(Object.values(err.response.data.errors)[i].toString());
            }
        });

    },


    getSingleorder({ commit }, id) {
        return axios.get(`/api/admin/orders/${id}`).then((response) => {
            commit("setorder", response.data)
            return response;
        }).catch((error) => {
            for (let i = 0; i < Object.keys(error.response.data).length; i++) {
                console.log(Object.values(error.response.data)[i].toString());
            }
        });
    }


};

export default {
    namespaced: true,
    name: "pending",
    state,
    getters,
    actions,
    mutations,
};